import * as React from "react";
import Helmet from "react-helmet";

import BigPicture from "../../components/realisations/bigPicture";
import { Portal } from "../../components/realisations/bigPicture";
import Button from "../../components/utils/button";
import eventBus from "../../components/utils/eventBus";
import { SplitText } from "@cyriacbr/react-split-text";
import { Link } from "gatsby";
import gsap from "gsap";

import tempPicture from "../../images/testing-image.jpg";

import coverPictureDesktop from "../../images/src/pages/realisations/desktop/portes_1_desktop.jpg";
import coverPictureDesktopR from "../../images/src/pages/realisations/desktop/portes_1_desktop@2x.jpg";
import contentPictureDesktopOne from "../../images/src/pages/realisations/desktop/portes_2_desktop.jpg";
import contentPictureDesktopOneR from "../../images/src/pages/realisations/desktop/portes_2_desktop@2x.jpg";
import contentPictureDesktopTwo from "../../images/src/pages/realisations/desktop/portes_3_desktop.jpg";
import contentPictureDesktopTwoR from "../../images/src/pages/realisations/desktop/portes_3_desktop@2x.jpg";
import contentPictureDesktopThree from "../../images/src/pages/realisations/desktop/portes_4_desktop.jpg";
import contentPictureDesktopThreeR from "../../images/src/pages/realisations/desktop/portes_4_desktop@2x.jpg";
import contentPictureDesktopFour from "../../images/src/pages/realisations/desktop/portes_5_desktop.jpg";
import contentPictureDesktopFourR from "../../images/src/pages/realisations/desktop/portes_5_desktop@2x.jpg";

import coverPictureMobile from "../../images/src/pages/realisations/mobile/portes_1_mobile.jpg";
import coverPictureMobileR from "../../images/src/pages/realisations/mobile/portes_1_mobile@2x.jpg";
import contentPictureMobileOne from "../../images/src/pages/realisations/mobile/portes_2_mobile.jpg";
import contentPictureMobileOneR from "../../images/src/pages/realisations/mobile/portes_2_mobile@2x.jpg";
import contentPictureMobileTwo from "../../images/src/pages/realisations/mobile/portes_3_mobile.jpg";
import contentPictureMobileTwoR from "../../images/src/pages/realisations/mobile/portes_3_mobile@2x.jpg";
import contentPictureMobileThree from "../../images/src/pages/realisations/mobile/portes_4_mobile.jpg";
import contentPictureMobileThreeR from "../../images/src/pages/realisations/mobile/portes_4_mobile@2x.jpg";
import contentPictureMobileFour from "../../images/src/pages/realisations/mobile/portes_5_mobile.jpg";
import contentPictureMobileFourR from "../../images/src/pages/realisations/mobile/portes_5_mobile@2x.jpg";

import nextPictureOne from "../../images/src/pages/realisations/mobile/bureaux_1_mobile.jpg";
import nextPictureTwo from "../../images/src/pages/realisations/mobile/bureaux_2_mobile.jpg";

import cursorOut from "../../images/zoom_out_map.svg";

import "../../styles/realisations/type.scss";

class Project extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false, currentImage: null };

    this.handleShow = this.handleShow.bind(this);
    this.handleHide = this.handleHide.bind(this);
  }
  componentDidMount() {
    //console.log(props)
    eventBus.on("closeBigPicture", () => {
      console.log("bigPicture is closed");
      this.handleHide();
    });

    gsap.set('.header', {opacity: 0})
    eventBus.on("Finalised", this.loadMotion);
  }

  loadMotion = () => {
    gsap.set('.header', {opacity: 1})
    let tl = gsap.timeline();
    tl.fromTo(".type .retour", { opacity: 0} , { opacity: 1 });
    tl.fromTo(
      ".type .big-title .line",
      { opacity: 0, y: 20 },
      { opacity: 1, y: 0, stagger: 0.2, duration: 0.6 }
    );
    tl.fromTo(".type picture", { opacity: 0} , { opacity: 1 });
  };

  handleShow(e) {
    if (window.innerWidth > 770) {
      this.setState({ currentImage: e.currentTarget });
      this.setState({ showModal: true });
      e.currentTarget.setAttribute("data-viewer", true);
    }
  }

  handleHide() {
    this.setState({ showModal: false });
    this.state.currentImage.removeAttribute("data-viewer", true);
  }

  render() {
    const modal = this.state.showModal ? (
      <Portal>
        <BigPicture
          height={this.state.currentImage.clientHeight}
          width={this.state.currentImage.clientWidth}
          top={this.state.currentImage.getBoundingClientRect().top}
          left={this.state.currentImage.getBoundingClientRect().left}
          object={this.state.currentImage}
        ></BigPicture>
      </Portal>
    ) : null;

    return (
      <>
        <Helmet>
          <title>Portes et fenêtre | Menuiserie Perret</title>
          <meta
            name="description"
            type="description"
            content="Découvrez nos réalisations de portes et fenêtres et autres aménagements extérieurs"
          />
        </Helmet>
        <section className="header type">
          <Link to="/realisations/boutiques-commerces">← Boutiques et commerces</Link>
          <h1 className="big-title">
            <SplitText
              LineWrapper={({ lineIndex, children }) => (
                <span className={"line wrapper" + " line" + lineIndex}>
                  {children}
                </span>
              )}
            >
              Portes et Fenêtres
            </SplitText>
          </h1>
          <picture data-src={coverPictureDesktopR}>
            <source media="(min-width: 770px)" srcSet={coverPictureDesktopR + ' 2x, ' + coverPictureDesktop + ' 1x'}/>
            <img src={coverPictureMobile} srcSet={coverPictureMobileR + ' 2x, ' + coverPictureMobile + ' 1x'} alt="Image de test"></img>
          </picture>
        </section>
        <section className="type__main">
          <div className="picture_viewer first" onClick={this.handleShow}>
            <button>
              <img src={cursorOut} alt="icone cursor"></img>
            </button>
            <picture data-src={contentPictureDesktopOneR}>
            <source media="(min-width: 770px)" srcSet={contentPictureDesktopOneR + ' 2x, ' + contentPictureDesktopOne + ' 1x'}/>
              <img src={contentPictureMobileOne} srcSet={contentPictureMobileOneR + ' 2x, ' + contentPictureMobileOne + ' 1x'} alt="Image de test"></img>
            </picture>
          </div>
          <div className="panel__deco"></div>
          <div className="picture_viewer secondary" onClick={this.handleShow}>
            <button>
              <img src={cursorOut} alt="icone cursor"></img>
            </button>
            <picture data-src={contentPictureDesktopTwoR}>
              <source media="(min-width: 770px)" srcSet={contentPictureDesktopTwoR + ' 2x, ' + contentPictureDesktopTwo + ' 1x'}/>
              <img src={contentPictureMobileTwo} srcSet={contentPictureMobileTwoR + ' 2x, ' + contentPictureMobileTwo + ' 1x'} alt="Image de test"></img>
            </picture>
          </div>
          <div className="tertiary">
            <div className="picture_viewer wrapper" onClick={this.handleShow}>
              <button>
                <img src={cursorOut} alt="icone cursor"></img>
              </button>
              <picture data-src={contentPictureDesktopThreeR}>
                <source media="(min-width: 770px)" srcSet={contentPictureDesktopThreeR + ' 2x, ' + contentPictureDesktopThree + ' 1x'}/>
                <img src={contentPictureMobileThree} srcSet={contentPictureMobileThreeR + ' 2x, ' + contentPictureMobileThree + ' 1x'} alt="Image de test"></img>
              </picture>
            </div>
            <div></div>
          </div>
        </section>
        <section className="type__catch">
          <div className="picture_viewer first" onClick={this.handleShow}>
            <button>
              <img src={cursorOut} alt="icone cursor"></img>
            </button>
            <picture data-src={contentPictureDesktopFourR}>
              <source media="(min-width: 770px)" srcSet={contentPictureDesktopFourR + ' 2x, ' + contentPictureDesktopFour + ' 1x'}/>
              <img src={contentPictureMobileFour} srcSet={contentPictureMobileFourR + ' 2x, ' + contentPictureMobileFour + ' 1x'} alt="Image de test"></img>
            </picture>
          </div>
          <div></div>
        </section>
        
        <section className="type__next">
        <picture>
            <img src={nextPictureOne} alt="Image de test" />
          </picture>
          <picture>
            <img src={nextPictureTwo} alt="Image de test" />
          </picture>
          <div>
            <h1 className="big-title">Bureaux et Collectivités</h1>
            <Button to="/realisations/collectivités" value="Réalisations suivantes">
              Réalisations suivantes
            </Button>
          </div>
        </section>
        {modal}
      </>
    );
  }
}
export default Project;
